export default function TwitterIcon(): JSX.Element {
    return (
        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.19844 17.8529C15.1754 17.8529 19.5377 11.2992 19.5377 5.61594C19.5377 5.42981 19.5339 5.24454 19.5254 5.06004C20.3742 4.45094 21.1068 3.69679 21.6887 2.83294C20.9116 3.17554 20.0754 3.40613 19.1982 3.51009C20.0936 2.97766 20.781 2.13533 21.1053 1.13128C20.254 1.63211 19.3226 1.98517 18.3514 2.17524C17.5601 1.33926 16.4334 0.816406 15.1858 0.816406C12.7909 0.816406 10.8488 2.74247 10.8488 5.11664C10.8488 5.45417 10.8869 5.78238 10.9613 6.0973C7.35689 5.91744 4.16077 4.20604 2.02176 1.60375C1.6365 2.2601 1.43386 3.00605 1.43454 3.76543C1.43454 5.25757 2.20014 6.5748 3.36444 7.34544C2.67574 7.32458 2.00216 7.1401 1.40034 6.80751C1.39969 6.82557 1.39969 6.84316 1.39969 6.86246C1.39969 8.9453 2.89455 10.6843 4.87894 11.0784C4.50625 11.1791 4.12167 11.23 3.73539 11.2297C3.45643 11.2297 3.18456 11.2025 2.92033 11.1522C3.47245 12.8611 5.07344 14.1046 6.97159 14.1394C5.48726 15.2931 3.61745 15.9803 1.58525 15.9803C1.23955 15.9806 0.894125 15.9607 0.550781 15.9207C2.47012 17.1408 4.74915 17.8526 7.19866 17.8526"
                fill="#1EA1F1"
            />
        </svg>
    );
}
