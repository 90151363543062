import React, { useEffect, useRef, useState } from 'react';
import formStyles from '@/components/forms/forms.module.scss';
import { Container, Form } from 'react-bootstrap';
import styles from '@/pages/index.module.scss';
import baseStyles from '@/styles/baseStyles.module.scss';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Button from '@/components/DesignSystem/Button';
import GoogleIcon from '@/icons/GoogleIcon';
import { signIn } from 'next-auth/react';
import useSignInWithEmail from '@/hooks/useSignInHelper';
import useSessionStorage from '@/hooks/useSessionStorage';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import Link from 'next/link';

type Email = { email: string };

const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email').max(255, 'Too Long').required('Please enter your email'),
});

export const SignupFormLayout = ({ logIn, emailSignIn, header, pending, signInWithGoogle, onSubmit, onCode }) => {
    const [signUpEmail] = useSessionStorage('signUpEmail');
    const [signUpCallbackUrl] = useSessionStorage('signUpCallbackUrl');
    const [inputValue, setInputValue] = useState('');
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const formRef = useRef<FormikProps<Email>>();
    const router = useRouter();

    useEffect(() => {
        if (inputValue.length !== 6) return;

        setIsInputDisabled(true);
        onCode(inputValue);
    }, [inputValue]);

    useEffect(() => {
        if (!formRef.current) return;

        const nextEmailValue = router.query.email || '';
        formRef.current.setFieldValue('email', nextEmailValue);
    }, [router.query]);

    const onInputChange = (event: any) => {
        const { value } = event.target;
        const allowInputChange = (/^\d+$/.test(value) && value.length <= 6) || value === '';
        if (allowInputChange) {
            setInputValue(value);
        }
    };

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    };

    const _onSubmit = async (values) => {
        const email = values.email;
        const trimmedEmail = email.trim().toLowerCase();
        onSubmit(trimmedEmail);
    };

    const renderError = (error) => {
        if (error) {
            return <div className={formStyles.error}>{error}</div>;
        }
    };

    if (emailSignIn) {
        return (
            <div className={formStyles.signupContainer}>
                <Container className={formStyles.signupInnerContainer} style={{ textAlign: 'center', gap: 10 }}>
                    <div className={baseStyles.signupFormHeaderBase}>Check your email</div>
                    <div className={baseStyles.or}>A sign in link has been sent to your email address.</div>
                    {signUpEmail && signUpCallbackUrl && (
                        <div className={baseStyles.or}>Copy and paste the code from the email here:</div>
                    )}
                    {signUpEmail && signUpCallbackUrl && (
                        <input
                            className={clsx(baseStyles.input, baseStyles.codeInput)}
                            type="text"
                            value={inputValue}
                            onChange={onInputChange}
                            placeholder="123456"
                            disabled={isInputDisabled}
                            autoFocus
                        />
                    )}
                </Container>
            </div>
        );
    }

    return (
        <div className={formStyles.signupContainer}>
            <Container className={formStyles.signupInnerContainer}>
                {header}
                <Formik
                    validationSchema={SignupSchema}
                    validateOnChange={false}
                    onSubmit={_onSubmit}
                    initialValues={{ email: router.query.email ?? '' }}
                >
                    {({ handleSubmit, handleChange, values, errors, setErrors, submitForm }) => (
                        <Form
                            noValidate
                            onSubmit={handleSubmit}
                            id="signupForm"
                            onKeyDown={onKeyDown}
                            className={formStyles.signupForm}
                        >
                            <Button
                                className={baseStyles.googleButton}
                                icon={GoogleIcon}
                                size="m"
                                variant="secondary"
                                onClick={signInWithGoogle}
                            >
                                {logIn ? 'Log in' : 'Sign up'} with Google
                            </Button>
                            <div className={styles.smallTextRow}>
                                <span className={baseStyles.or}>or</span>
                            </div>
                            <Form.Control
                                className={baseStyles.input}
                                type="text"
                                placeholder="Email"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        submitForm();
                                    }
                                }}
                                onChange={(event) => {
                                    setErrors({});
                                    handleChange(event);
                                }}
                                name="email"
                                value={values.email}
                            />
                            {renderError(errors.email)}
                            <Button
                                type="submit"
                                disabled={pending}
                                className={baseStyles.signupButton}
                                style={{ width: '100%' }}
                                size="m"
                                variant="primary"
                            >
                                {pending ? 'Checking Email...' : (logIn ? 'Log in' : 'Sign up') + ' with Email'}
                            </Button>
                            <div className={baseStyles.signInLink} style={{ cursor: 'pointer' }}>
                                or{' '}
                                {!logIn && (
                                    <a onClick={() => signIn()} className={styles.link}>
                                        log in
                                    </a>
                                )}
                                {logIn && (
                                    <Link href="/signup">
                                        <a className={styles.link}>sign up</a>
                                    </Link>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </div>
    );
};

const SignupForm = ({
    galacticaInterest,
    header,
    sub,
    noRedirect,
    callbackUrl,
    checkIfExists,
}: {
    callbackUrl?: string;
    noRedirect?: boolean;
    galacticaInterest?: boolean;
    // bypassWaitlist?: boolean;
    // bypassWaitlistKey?: string;
    header?: string;
    sub?: string;
    isBaseApp?: boolean;
    checkIfExists?: (exists: boolean, email: string) => void;
}): React.ReactElement => {
    const signInWithEmail = useSignInWithEmail();
    const [pending, setPending] = useState(false);
    const [signUpEmail, , refreshSignUpEmail] = useSessionStorage('signUpEmail');
    const [signUpCallbackUrl, , refreshSignUpCallbackUrl] = useSessionStorage('signUpCallbackUrl');
    const [, setGalacticaInterest] = useSessionStorage('galacticaInterest');
    const [emailSignIn, setEmailSignIn] = useState(false);
    const redirect = !noRedirect;

    const onCode = (code) => {
        const searchParams = new URLSearchParams();
        searchParams.set('callbackUrl', signUpCallbackUrl);
        searchParams.set('token', code);
        searchParams.set('email', signUpEmail);

        // for some reason just sending request and calling update
        // does not actually update session, so just reload page
        window.location.href = `/api/auth/callback/email?${searchParams.toString()}`;
    };

    const setSessionParams = () => {
        console.log('xxxx', galacticaInterest);
        setGalacticaInterest(galacticaInterest);
    };

    const setSessionAndSignInWithEmail = (email) => {
        setSessionParams();
        const defaultCallbackUrl = `${window?.location.origin}/processRedirects`;
        signInWithEmail({ email: email, callbackUrl: callbackUrl ?? defaultCallbackUrl, redirect });
        if (noRedirect) {
            setEmailSignIn(true);
            refreshSignUpEmail();
            refreshSignUpCallbackUrl();
        }
    };

    const checkEmail = async (email) => {
        try {
            setPending(true);
            const response = await fetch('/api/getUserStatusByEmail?email=' + email);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const user = await response.json();
            checkIfExists(user?.exists, email);

            // console.log('User created successfully:', data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
        setPending(false);
    };

    const signInWithGoogle = () => {
        setSessionParams();
        const defaultCallbackUrl = `${window?.location.origin}/processRedirects`;
        signIn('google', { callbackUrl: callbackUrl ?? defaultCallbackUrl, redirect });
    };
    const _header = (
        <div className={baseStyles.signupFormHeaderBase}>
            {header}
            {sub && (
                <span className={`${formStyles.textXSMedium} secondaryText`} style={{ marginBottom: '-10px' }}>
                    {sub}
                </span>
            )}
        </div>
    );

    return (
        <SignupFormLayout
            logIn={false}
            emailSignIn={emailSignIn}
            header={_header}
            onCode={onCode}
            onSubmit={checkIfExists ? checkEmail : setSessionAndSignInWithEmail}
            pending={pending}
            signInWithGoogle={signInWithGoogle}
        />
    );
};

export default SignupForm;
