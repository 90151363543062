import { uploadFiles } from '@/utils/helpers';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import formStyles from '@/components/forms/forms.module.scss';
import Button from '@/components/DesignSystem/Button';
import AvatarButton from '@/components/DesignSystem/Button/AvatarButton';
import OccupationDropdown from '@/components/modals/WelcomeModal/subcomponents/OccupationDropdown';
import Text from '@/components/DesignSystem/Text';
import baseStyles from '@/styles/baseStyles.module.scss';
import styles from './styles.module.scss';
import Link from 'next/link';

const occupationDropdownStyles = {
    input: (provided) => ({
        ...provided,
        lineHeight: '22px',
        fontSize: 17,
        paddingBottom: 0,
        paddingTop: 0,
        margin: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgba(0, 160, 247, 0.15)' : 'none',
        borderRadius: 3,
        color: state.isSelected ? '#00A0F7' : 'black',
        fontSize: 17,
        marginLeft: 6,
        marginRight: 6,
        padding: '12px 18px',
        width: 'calc(100% - 12px)',
    }),
    control: (provided) => ({
        ...provided,
        border: 'none',
        cursor: 'pointer',
        outline: '0.5px solid #BFBFBF',
        padding: '12px 18px',
        borderRadius: 12,
        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.10)',
        '&:hover': {
            outline: '1.5px solid #007AFF',
        },
        backgroundColor: 'linear-gradient(180deg, #FFF 0%, #FCFCFC 100%)',
    }),
    dropdownIndicator: (provided) => ({ ...provided, padding: 0, svg: { fill: '#00a0f7' } }),
    valueContainer: (provided) => ({ ...provided, padding: 0, fontSize: 17, lineHeight: '22px' }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: 17,
        lineHeight: '26px',
        width: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        border: 'none',
        borderRadius: 5,
        boxShadow: '0px 5px 30px 0px #00000026, 0px 4px 10px #00000026',
        overflow: 'hidden',
        padding: '2px 0',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#8B979C',
        padding: 0,
        margin: 0,
        fontSize: 17,
        lineHeight: '22px',
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
};

const PersonalInfoStepSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
});

const PersonalInfoStep = function ({ onNext, entity, email, reset, pending }): React.ReactElement {
    // const dispatch = useDispatch();

    const handleOccupationChange = (setFieldValue) => (option) => {
        setFieldValue('occupation', option.value);
    };

    const handleAvatarChange =
        (setFieldValue, field) =>
        (avatar: File): void => {
            uploadFiles([avatar], {}).then((docEntities) => {
                const url = docEntities[0]?.url;
                setFieldValue(field, url);
                // dispatch(updateEntity(userId, { ...entity, [field]: url }, 'WelcomeModal3'));
            });
        };

    return (
        <Formik
            initialValues={{
                email: entity?.email,
                name: entity?.name,
                avatarUrl: entity?.avatarUrl,
                occupation: entity?.occupation,
            }}
            validationSchema={PersonalInfoStepSchema}
            validateOnChange={false}
            onSubmit={onNext}
        >
            {({ handleSubmit, handleChange, values, touched, errors, isSubmitting, setFieldValue }): JSX.Element => (
                <Form onSubmit={handleSubmit} className={styles.personalInfoForm}>
                    <div className={styles.popupHeader}>
                        <Text variant="heading" size="h4" align="center" weight="bold" style={{ margin: 0 }}>
                            Welcome!
                        </Text>
                        <Text variant="inactive" size="s" align="center">
                            Tell us a little about yourself
                        </Text>
                    </div>
                    <AvatarButton onChange={handleAvatarChange(setFieldValue, 'avatarUrl')} value={values.avatarUrl} />
                    <div style={{ width: '100%' }}>
                        <div style={{ marginBottom: 16 }}>
                            <div className={styles.personalInfoTitle}>Name</div>
                            <Form.Control
                                className={baseStyles.input}
                                id="your-name-input"
                                type="text"
                                placeholder="John Doe"
                                onChange={handleChange}
                                name="name"
                                disabled={isSubmitting}
                                value={values.name}
                                isValid={touched.name && !errors.name}
                            />
                            {errors.name && <div className={formStyles.error}>{errors.name.toString()}</div>}
                        </div>
                        <div>
                            <div className={styles.personalInfoTitle}>Role</div>
                            <OccupationDropdown
                                value={values.occupation}
                                onChange={handleOccupationChange(setFieldValue)}
                                menuPortalTarget={document.body}
                                styles={occupationDropdownStyles}
                            />
                        </div>
                    </div>
                    <Button
                        type="submit"
                        size="s"
                        variant="primary"
                        className={baseStyles.signupButton}
                        disabled={pending}
                    >
                        {pending ? 'Creating Profile...' : 'Next →'}
                    </Button>
                    <Text variant="inactive" size="xs" align="center" style={{ whiteSpace: 'initial' }}>
                        The linked email is <b>{email}</b> {' - '}
                        <span onClick={reset}>
                            <Link href="/base">click to change</Link>
                        </span>
                    </Text>
                </Form>
            )}
        </Formik>
    );
};

export default PersonalInfoStep;
