export default function DiscordIcon(): JSX.Element {
    return (
        <svg width="514" height="514" viewBox="0 0 514 514" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4683_7119)">
                <path
                    d="M435.407 90.5455C402.135 74.9783 366.559 63.6647 329.365 57.2227C324.797 65.4812 319.46 76.589 315.781 85.4253C276.243 79.4792 237.068 79.4792 198.257 85.4253C194.579 76.589 189.121 65.4812 184.512 57.2227C147.278 63.6647 111.661 75.0199 78.3891 90.628C11.2789 192.04 -6.91354 290.934 2.18271 388.423C46.6937 421.663 89.8303 441.855 132.239 455.068C142.71 440.657 152.048 425.338 160.093 409.193C144.771 403.371 130.096 396.186 116.23 387.845C119.908 385.12 123.507 382.27 126.983 379.339C211.558 418.896 303.451 418.896 387.015 379.339C390.532 382.27 394.13 385.12 397.768 387.845C383.861 396.227 369.146 403.412 353.824 409.234C361.869 425.338 371.167 440.698 381.678 455.109C424.128 441.896 467.304 421.704 511.815 388.423C522.489 275.408 493.583 177.423 435.407 90.5455ZM171.616 328.468C146.227 328.468 125.406 304.766 125.406 275.903C125.406 247.041 145.782 223.298 171.616 223.298C197.449 223.298 218.269 246.999 217.825 275.903C217.865 304.766 197.449 328.468 171.616 328.468ZM342.383 328.468C316.994 328.468 296.174 304.766 296.174 275.903C296.174 247.041 316.549 223.298 342.383 223.298C368.216 223.298 389.036 246.999 388.592 275.903C388.592 304.766 368.216 328.468 342.383 328.468Z"
                    fill="#5865F2"
                />
            </g>
            <defs>
                <clipPath id="clip0_4683_7119">
                    <rect width="514" height="514" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
