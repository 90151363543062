import { SignInOptions, signIn } from 'next-auth/react';
import useSessionStorage from './useSessionStorage';

const useSignInWithEmail = () => {
    const [, setSignUpEmail] = useSessionStorage('signUpEmail');
    const [, setSignUpCallbackUrl] = useSessionStorage('signUpCallbackUrl');

    const setSessionStorageAndSignIn = ({ email, callbackUrl, ...options }: SignInOptions & { email: string }) => {
        email = email.toLowerCase();
        setSignUpEmail(email);
        setSignUpCallbackUrl(callbackUrl);
        signIn('email', { email, callbackUrl, ...options });
    };

    return setSessionStorageAndSignIn;
};

export default useSignInWithEmail;
