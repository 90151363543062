import Text from '@/components/DesignSystem/Text';
import baseOnboardingStyles from '@/components/baseOnboarding/baseOnboarding.module.scss';
import styles from './styles.module.scss';
import clsx from 'clsx';
import TwitterIcon from '@/icons/TwitterIcon';
import DiscordIcon from '@/icons/DiscordIcon';

const WaitlistStep = function (): React.ReactElement {
    const tweetText = encodeURIComponent(
        ".@bedrockcomputer I'm excited about Bedrock because: ... One thing that bothers me about macOS is: ... ",
    );

    return (
        <div className={styles.downloads}>
            <div className={styles.popupHeader}>
                <Text variant="heading" size="h4" align="center" weight="bold" style={{ margin: 0 }}>
                    Welcome to the waitlist!
                </Text>
                <Text variant="inactive" size="s" align="center">
                    Do these things to get quicker access:
                </Text>
            </div>
            <div className={styles.waitlistSteps}>
                <div className={styles.steps}>
                    <div className={styles.step}>
                        <div className={styles.roundNumber}>1</div>
                        <Text size="m" align="center" style={{ margin: 0 }}>
                            Follow us on Twitter
                        </Text>
                        <a
                            target="_blank"
                            href="https://twitter.com/bedrockcomputer?ref_src=twsrc%5Etfw"
                            data-show-count="false"
                            rel="noreferrer"
                        >
                            <button className={clsx(baseOnboardingStyles.continueButton, styles.downloadButton)}>
                                <TwitterIcon />
                                Follow
                            </button>
                        </a>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.roundNumber}>2</div>
                        <Text size="m" align="center" style={{ margin: 0 }}>
                            Tweet{' '}
                            <a
                                target="_blank"
                                className={styles.link}
                                href="https://twitter.com/bedrockcomputer"
                                rel="noreferrer"
                            >
                                @bedrockcomputer
                            </a>{' '}
                            about<br></br>
                            what bothers you with MacOS or<br></br>
                            what excites you about Bedrock
                        </Text>
                        <a
                            href={`https://twitter.com/share?ref_src=twsrc%5Etfw&text=${tweetText}`}
                            data-url="https://bedrock.computer"
                            data-show-count="false"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button className={clsx(baseOnboardingStyles.continueButton, styles.downloadButton)}>
                                <TwitterIcon />
                                Tweet
                            </button>
                        </a>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.roundNumber}>3</div>
                        <Text size="m" align="center" style={{ margin: 0 }}>
                            Join our Discord and holler at us
                        </Text>
                        <a
                            target="_blank"
                            className={styles.link}
                            href="https://discord.gg/C94bFjya5D"
                            rel="noreferrer"
                        >
                            <button className={clsx(baseOnboardingStyles.continueButton, styles.downloadButton)}>
                                <DiscordIcon />
                                Join us on Discord
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WaitlistStep;
