import BedrockDiscoIcon from '@/icons/BedrockDiscoIcon';
import Image from 'next/image';
import finderIcon from '/public/icons/apps/finder.svg';
import styles from './styles.module.scss';
import Link from 'next/link';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SignupFlowHeader = ({ onGetStartedClick = () => {}, title = '', entryPoint = '' }) => {
    return (
        <div className={styles.stickyHeader}>
            <div className={styles.stickyHeaderIcons}>
                <div className={styles.stickyHeaderDiscoIcon}>
                    <BedrockDiscoIcon />
                </div>
                <div className={styles.stickyHeaderBaseIcon}>
                    {entryPoint === 'base' && (
                        <Image
                            src={finderIcon.src}
                            width={20}
                            height={20}
                            alt=""
                            className={styles.stickyHeaderBaseIconImg}
                        />
                    )}
                    {entryPoint === 'gal' && (
                        <Image
                            src={'/images/landingPage/bedrockHardwareIcon.png'}
                            width={23}
                            height={20}
                            alt=""
                            className={styles.stickyHeaderBaseIconImg}
                        />
                    )}
                    {title}
                </div>
            </div>
            <div className={styles.stickyHeaderButtons}>
                <Link href="/login" passHref>
                    <button
                        className={styles.stickyHeaderButtonLogIn}
                        style={entryPoint == 'home' ? { color: 'white' } : {}}
                    >
                        Log In
                    </button>
                </Link>
                <button className={styles.stickyHeaderButtonGetStarted} onClick={onGetStartedClick}>
                    Get Started
                </button>
            </div>
        </div>
    );
};

export default SignupFlowHeader;
